import React from "react";
import { graphql } from "gatsby";
import { useIntl } from "gatsby-plugin-intl";

import HeroWithImage from "../components/heroWithImage/index";
import Blog from "../containers/news/blog";
import Layout from "../components/layout";
import Seo from "../components/seo";

import heroDesktop from "../assets/heroDesktop/News.png";
import heroMobile from "../assets/heroMobile/News.png";

const CorporateDirectory = ({ data }) => {
  const { edges } = data.news;
  const intl = useIntl();
  return (
    <Layout inverted>
      <Seo title="NEWS" />
      <HeroWithImage
        title={intl.locale === "fr" ? "NOUVELLES" : "NEWS"}
        heroMobile={heroMobile}
        heroDesktop={heroDesktop}
      />
      <Blog data={edges} />
    </Layout>
  );
};

export const query = graphql`
  query NewsQuery($locale: String) {
    hero: allContentfulNewsPageHero(filter: { node_locale: { eq: $locale } }) {
      nodes {
        heroImage {
          file {
            url
          }
        }
        title
      }
    }
    news: allContentfulNews(filter: { node_locale: { eq: $locale } }, sort: { fields: date, order: DESC }) {
      edges {
        node {
          ctaText
          ctaLink
          title
          date(formatString: "MMMM DD, YYYY")
          content {
            raw
          }
        }
      }
    }
  }
`;

export default CorporateDirectory;
